






















































import { Vue, Component } from "vue-property-decorator";
import Api from "@/api";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import nameof from "@/utility/nameof";
import { getModule } from "vuex-module-decorators";
import { CrewMemberCertificateModel } from "@/api/generated";
import SnackbarModule from "@/store/snackbarModule";
import { DataTableHeader } from "vuetify";
import UserModule from "@/store/userModule";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component
export default class CrewMemberExpiringCertificatesTable extends Vue {
  private loading = false;
  private items: Array<CrewMemberCertificateModel> = [];
  private headers: Array<DataTableHeader<CrewMemberCertificateModel>> = [
    { text: "", value: "actions", sortable: false },
    {
      text: "Name",
      value: nameof<CrewMemberCertificateModel>("crewMemberFullname")
    },
    { text: "Filename", value: nameof<CrewMemberCertificateModel>("filename") },
    {
      text: "Certificate Type",
      value: nameof<CrewMemberCertificateModel>("certificateTypeName")
    },
    {
      text: "Current Vessel",
      value: nameof<CrewMemberCertificateModel>("currentVessel")
    },
    {
      text: "Reference",
      value: nameof<CrewMemberCertificateModel>("reference")
    },
    { text: "Expiry", value: nameof<CrewMemberCertificateModel>("expiryDate") },
    {
      text: "Created",
      value: nameof<CrewMemberCertificateModel>("createdUtc")
    },
    { text: "Dealt With", value: "dealtWith" }
  ];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    await this.getCertificates();
  }

  private async getCertificates() {
    try {
      this.loading = true;
      if (userModule.role == "AGM") {
        const response = await Api.CrewMemberCertificateService.apiCrewmembercertificateExpiringUserIdGet(
          userModule.userId
        );
        this.items = response.data;
      } else {
        const response = await Api.CrewMemberCertificateService.apiCrewmembercertificateExpiringGet();
        this.items = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch certificates");
    } finally {
      this.loading = false;
    }
  }

  private async onEditCertificate(certificate: CrewMemberCertificateModel) {
    if (!certificate.crewMemberCertificateId) {
      return;
    }

    if (!certificate.crewMemberId) {
      return;
    }
    this.$router.push({
      name: "Crew Member Certificate Edit",
      params: { crewMemberId: certificate.crewMemberId }
    });
  }

  private async onDownloadCertificate(certificate: CrewMemberCertificateModel) {
    if (!certificate.documentId) {
      return;
    }

    const fileResponse = await Api.DocumentService.apiDocumentDocumentIdGet(
      certificate.documentId
    );

    FileDownloadUtility.DownloadFile(fileResponse.data, certificate.filename);
  }

  private async onDeleteCertificate(certificate: CrewMemberCertificateModel) {
    if (!certificate.crewMemberCertificateId) {
      return;
    }

    try {
      this.loading = true;
      await Api.CrewMemberCertificateService.apiCrewmembercertificateCrewMemberCertificateIdDelete(
        certificate.crewMemberCertificateId
      );
      snackbarModule.setSnackbarMessage("Certificate deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete certificate");
    } finally {
      this.loading = false;
    }

    await this.getCertificates();
  }

  private async updateCertificateDealtWith(item: CrewMemberCertificateModel) {
    if (!item.crewMemberCertificateId) {
      return;
    }
    try {
      await Api.CrewMemberCertificateService.apiCrewmembercertificateCrewMemberCertificateIdPut(
        item.crewMemberCertificateId,
        item
      );
      snackbarModule.setSnackbarMessage("Certificate updated");
      this.$emit("certificate-updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update certificate");
    } finally {
      this.getCertificates();
    }
  }
}
