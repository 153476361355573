var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"container-xl"},[_c('div',{staticClass:"crew-member-expiring-certificates-table"},[_c('base-data-table',{attrs:{"title":"Expiring Certificates","no-create":"","items":_vm.items,"headers":_vm.headers,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.expiryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.expiryDate))+" ")]}},{key:"item.createdUtc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdUtc))+" ")]}},{key:"item.dealtWith",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.agencyAdminRole},on:{"click":function($event){return _vm.updateCertificateDealtWith(item)}},model:{value:(item.dealtWith),callback:function ($$v) {_vm.$set(item, "dealtWith", $$v)},expression:"item.dealtWith"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(!_vm.agencyAdminRole)?_c('v-list-item',{on:{"click":function($event){return _vm.onEditCertificate(item)}}},[_c('v-list-item-title',[_vm._v("View")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.onDownloadCertificate(item)}}},[_c('v-list-item-title',[_vm._v("Download")])],1),(!_vm.agencyAdminRole)?_c('v-list-item',{on:{"click":function($event){return _vm.onDeleteCertificate(item)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1):_vm._e()],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }